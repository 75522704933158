<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <div class="ml-2">
            <b-button
              variant="primary"
              v-b-modal.priceModal
              class="btn-tour-skip mt-1"
            >
              <FeatherIcon icon="PlusCircleIcon" />
              <span class="mx-2 align-middle">Add New Price</span>
            </b-button>
          </div>
          <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
            <template v-slot="{ props }">
              <div
                v-if="props.column.field === 'action'"
                class="d-flex justify-content-center"
              >
                <div>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="editPrice(props.row)">
                      <feather-icon size="16" icon="EditIcon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deletePrice(props.row)">
                      <feather-icon size="16" icon="TrashIcon" class="mr-50" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </template>
          </MyTable>
          <div class="d-flex align-items-center">
            <b-form-group class="mr-1" label="Tax Charged">
              <b-form-input />
            </b-form-group>
            <b-button class="mt-1" variant="primary"
              >Update Tax Charge</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="priceModal"
      :title="isEditing ? 'Edit Price' : 'Add New Price'"
      hide-footer
      centered
      size="lg"
    >
      <validation-observer ref="simpleRules">
        <b-row
          ><b-col>
            <b-form-group label="Member Type">
              <validation-provider
                #default="{ errors }"
                name="Member Type"
                rules="required"
              >
                <b-form-radio-group v-model="newPrice.membershipId">
                  <b-form-group
                    v-for="(option, index) in membershipId"
                    :key="index"
                  >
                    <b-form-radio :value="option.id">{{
                      option.membership
                    }}</b-form-radio>
                  </b-form-group>
                </b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group></b-col
          ><b-col>
            <b-form-group label="Unit Price">
              <validation-provider
                #default="{ errors }"
                name="Unit Price"
                rules="required"
              >
                <b-form-input v-model="newPrice.unitPrice" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Double Charged">
              <validation-provider
                #default="{ errors }"
                name="Double Charged"
                rules="required"
              >
                <b-form-input v-model="newPrice.doubleCharge" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            ></b-col
          ><b-col>
            <b-form-group label="Unit Price Cost">
              <validation-provider
                #default="{ errors }"
                name="Unit Price Cost"
                rules="required"
              >
                <b-form-input v-model="newPrice.unitPriceCost" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Double Charged Cost">
              <validation-provider
                #default="{ errors }"
                name="Double Charged Cost"
                rules="required"
              >
                <b-form-input
                  v-model="newPrice.doubleChargeCost"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col></b-row
        >

        <b-row class="justify-content-center">
          <b-col cols="6">
            <b-form-group label="Select Category">
              <validation-provider
                #default="{ errors }"
                name="Select Category"
                rules="required"
              >
                <vSelect
                  v-model="newPrice.categoryId"
                  :reduce="(category) => category.id"
                  :options="category"
                  label="category"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Convert Risk">
              <validation-provider
                #default="{ errors }"
                name="Convert Risk"
                rules="required"
              >
                <b-form-input v-model="newPrice.convertRisk" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="d-flex justify-content-center my-2">
          <b-button variant="primary" @click="submitPrice()">Submit</b-button>
        </b-row>
      </validation-observer>
    </b-modal>
  </b-container>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  data() {
    return {
      dateFilter: {},
      dummyData: {},
      newPrice: {},
      type: "",
      isLoading: true,
      orderID: null,
      idx: null,
      filter: "Active",
      optionsStatus: {
        "In Warehouse": "In Warehouse",
        "Arrived At Branch": "Arrived At Branch",
        "Out For Delivery": "Out For Delivery",
        Delivered: "Delivered",
        "Close Store": "Close Store",
        "Second Delivery": "Second Delivery",
        "Assigned for Last Mile": "Assigned for Last Mile",
      },
      optionsCancel: {
        "Duplicate Order": "Duplicate Order",
        "Wrong Recipient's Address": "Wrong Recipient's Address",
        "Client Not Ready for Pick Up": "Client Not Ready for Pick Up",
        "Cancel Order": "Cancel Order",
        "IT Testing Order": "IT Testing Order",
      },
      filterLabels: [
        { label: "Active" },
        { label: "Assigned" },
        { label: "Scheduled" },
        { label: "History" },
        { label: "Cancelled" },
        { label: "Failed Payment" },
      ],
      columns: [
        {
          label: "Member Type",
          field: "membership.membership",
          tdClass: "text-center align-middle",
        },
        {
          label: "Category",
          field: "category.name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Unit Price",
          field: "unit_price",
          tdClass: "text-center align-middle",
        },
        {
          label: "Unit Price Cost",
          field: "unit_price_cost",
          tdClass: "text-center align-middle",
        },
        {
          label: "Double Charged",
          field: "double_charge",
          tdClass: "text-center align-middle",
        },
        {
          label: "Double Charged Cost",
          field: "double_charge_cost",
          tdClass: "text-center align-middle",
        },
        {
          label: "Convert Risk",
          field: "convert_risk",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      category: [],
      required,
      membershipId: [],
      isEditing: "",
      isLoading: false,
    };
  },

  async created() {
    this.getOrders();
    this.getCategories();
    this.getMembership();
    this.isLoading = false;
  },

  methods: {
    getOrders() {
      this.isLoading = true;
      this.$http.get(`new_price_checker`).then((response) => {
        if (response.data.data?.length > 0) {
          this.rows = [...response.data.data];
        } else {
          this.rows = [];
        }
        this.isLoading = false;
      });
    },

    getMembership() {
      this.isLoading = true;
      this.$http.get(`get_membership_type_list`).then((response) => {
        this.membershipId = response.data.data;
        this.isLoading = false;
      });
    },

    async submitPrice() {
      this.isLoading = true;
      const isValid = await this.$refs.simpleRules.validate();
      if (!isValid) {
        return;
      }
      this.$bvModal.hide("priceModal");
      if (this.isEditing) {
        this.$http
          .put(`new_price_checker/${this.isEditing}`, this.newPrice)
          .then((response) => {
            this.$toast.success(response.data.message, {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.getOrders();
            this.newPrice = {};
            this.isEditing = "";
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      } else {
        this.$http
          .post("new_price_checker", this.newPrice)
          .then((response) => {
            if (response.data.status) {
              this.$toast.success(response.data.message, {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
            } else {
              let errors = response.data.errors;
              for (let error in errors) {
                for (let i = 0; i < errors[error].length; i++) {
                  this.$toast.error(errors[error][i], {
                    position: "top-right",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false,
                  });
                }
              }
            }
            this.getOrders();
            this.newPrice = {};
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
          });
      }
    },

    getCategories() {
      this.$http
        .get("get_location_category")
        .then((response) => {
          this.category = response.data.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    editPrice(row) {
      this.newPrice.membershipId = row.membership.id;
      this.newPrice.unitPrice = row.unit_price;
      this.newPrice.doubleCharge = row.double_charge;
      this.newPrice.unitPriceCost = row.unit_price_cost;
      this.newPrice.doubleChargeCost = row.double_charge_cost;
      this.newPrice.convertRisk = row.convert_risk;
      this.newPrice.categoryId = row.category.id;
      this.isEditing = row.id;
      this.$bvModal.show("priceModal");
    },

    async deletePrice(row) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to delete this price.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-2",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          const response = await this.$http.delete(
            `new_price_checker/${row.id}`
          );
          if (response.data.status === true) {
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            this.getOrders();
            this.isLoading = false;
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
